export const RouteName = {
  ONBOARDING: 'onboarding',
  ONBOARDING_REQUEST: 'onboardingRequest',
  ONBOARDING_REGISTRATION: 'onboardingRegistration',

  LOGIN: 'login',
  RESET_PASSWORD: 'resetPassword',

  // admins
  ADMIN_ACTIONS: 'adminActions',
  CREDITORS: 'creditors',
  USERS: 'users',
  ADMINS: 'admins',
  REQUESTS: 'requests',
  ASSIGN_DEBTORS: 'assignDebtors',

  // authorized creditors
  DASHBOARD: 'dashboard',
  VOUCHERS: 'vouchers',
  DEBTORS: 'debtors',
  PERMANENT_DEBTS: 'permanentDebts',
  UNPROCESSED: 'unprocessed',
  MASTER_DATA: 'masterData',
  SETTINGS: 'settings',
  HELP_CENTER: 'helpCenter',
  WORK_QUEUE: 'workQueue',
  UPDATE_PASSWORD: 'updatePassword',
  OPT_IN: 'optIn',
  USER_INFO: 'userInfo'
};

export default RouteName;
