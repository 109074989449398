import showSnackbar from '@/helper/showSnackbar';
import {
  mapUsers,
  mapCreditors,
  mapRequests,
  mapAdminUsers,
  mapDebtorAssignmentList,
  mapCreditorServiceFeeConditions,
  mapServiceFeeMatrixList,
  mapDebtorAssignmentEntry
} from './models';
import qs from 'qs';

export const NAMESPACE = 'admin';

const initialState = () => ({
  users: [],
  usersCount: 0,
  creditors: [],
  creditorsCount: 0,
  creditorNames: [],
  creditorSapNumbers: [],
  creditorUsernames: [],
  creditorCities: [],
  admins: [],
  requests: [],
  requestsCount: 0,
  debtorAssignmentList: [],
  debtorAssignmentListCount: 0,
  loadingList: false
});

export default (Vue) => ({
  namespaced: true,

  state: initialState(),

  getters: {
    GET_CREDITOR_BY_ID: (state) => (id) => state.creditors.find((creditor) => creditor.id === id),
    GET_ADMIN_BY_ID: (state) => (id) => state.admins.find((user) => user.id === id),
    GET_USER_BY_ID: (state) => (id) => {
      const user = state.users.find((user) => user.id === id);

      if (user) {
        return user;
      }

      return state.admins.find((user) => user.id === id);
    },
    GET_REQUEST_BY_ID: (state) => (id) => state.requests.find((request) => request.id === id)
  },

  mutations: {
    RESET_LOADING_LIST(state) {
      state.loadingList = false;
    },
    SET_LOADING_LIST(state) {
      state.loadingList = true;
    },
    SET_USERS(state, { users, count }) {
      state.users = users;
      state.usersCount = count;
    },
    SET_CREDITORS(state, { creditors, count }) {
      state.creditors = creditors;
      state.creditorsCount = count;
    },
    SET_CREDITOR_NAMES(state, names) {
      state.creditorNames = names;
    },
    SET_CREDITOR_USERNAMES(state, usernames) {
      state.creditorUsernames = usernames;
    },
    SET_CREDITOR_SAP_NUMBERS(state, sapNumbers) {
      state.creditorSapNumbers = sapNumbers;
    },
    SET_CREDITOR_CITIES(state, cities) {
      state.creditorCities = cities;
    },
    SET_ADMINS(state, admins) {
      state.admins = admins;
    },
    ADD_PERMISSION(state, { userIdentifier, permission }) {
      const index = state.admins.findIndex((entry) => entry.id === userIdentifier);

      if (index === -1) {
        return;
      }

      state.admins[index].permissions = [...state.admins[index].permissions, permission];
    },
    REMOVE_PERMISSION(state, { userIdentifier, permission }) {
      const index = state.admins.findIndex((entry) => entry.id === userIdentifier);

      if (index === -1) {
        return;
      }

      state.admins[index].permissions = state.admins[index].permissions.filter(
        (item) => item !== permission
      );
    },
    SET_REQUESTS(state, { requests, count }) {
      state.requests = requests;
      state.requestsCount = count;
    },
    SET_DEBTOR_ASSIGNMENT_LIST(state, { items, count }) {
      state.debtorAssignmentList = items;
      state.debtorAssignmentListCount = count;
    },
    RESET_STATE(state) {
      const initial = initialState();
      Object.keys(initial).forEach((key) => {
        state[key] = initial[key];
      });
    }
  },

  actions: {
    async fetchUserToken(_, userId) {
      try {
        const response = await Vue.prototype.$http.get(`/user-management/user/${userId}/switch`);

        return { content: response.data };
      } catch (error) {
        showSnackbar({ text: 'serverError', color: 'error' });
        return { error };
      }
    },

    async fetchCreditors({ commit }, params) {
      commit('SET_LOADING_LIST');
      try {
        const { data } = await Vue.prototype.$http.get('/creditor/list', {
          params,
          paramsSerializer: (params) => {
            return qs.stringify(params, { arrayFormat: 'brackets' });
          }
        });

        commit('SET_CREDITORS', { creditors: mapCreditors(data.creditors), count: data.count });
        commit('RESET_LOADING_LIST');
        return {};
      } catch (error) {
        showSnackbar({ text: 'serverError', color: 'error' });
        commit('RESET_LOADING_LIST');
        return { error };
      }
    },

    async fetchCreditorNames({ commit }) {
      try {
        const response = await Vue.prototype.$http.get('/creditor/name-list');

        commit('SET_CREDITOR_NAMES', response.data);
        return {};
      } catch (error) {
        showSnackbar({ text: 'serverError', color: 'error' });
        return { error };
      }
    },

    async fetchCreditorUsernames({ commit }, { includeCompanyUsers }) {
      try {
        const response = await Vue.prototype.$http.get('/creditor/username-list', {
          params: { companyUser: includeCompanyUsers }
        });

        commit('SET_CREDITOR_USERNAMES', response.data);
        return {};
      } catch (error) {
        showSnackbar({ text: 'serverError', color: 'error' });
        return { error };
      }
    },

    async fetchCreditorSapNumbers({ commit }) {
      try {
        const response = await Vue.prototype.$http.get('/creditor/sap-number-list');

        commit('SET_CREDITOR_SAP_NUMBERS', response.data);
        return {};
      } catch (error) {
        showSnackbar({ text: 'serverError', color: 'error' });
        return { error };
      }
    },

    async fetchCreditorCities({ commit }) {
      try {
        const response = await Vue.prototype.$http.get('/creditor/city-list');

        commit('SET_CREDITOR_CITIES', response.data);
        return {};
      } catch (error) {
        showSnackbar({ text: 'serverError', color: 'error' });
        return { error };
      }
    },

    async fetchDetails(_, creditor) {
      try {
        const { data } = await Vue.prototype.$http.get(`/creditor/${creditor}/details`);
        return { content: data };
      } catch (error) {
        return { error };
      }
    },

    async createCreditor(_, payload) {
      try {
        await Vue.prototype.$http.post('/creditor/register', payload);
        return {};
      } catch (error) {
        return { error };
      }
    },

    async fetchServiceFeeMatrixList(_, { page, rowsPerPage, filters, sorting }) {
      try {
        const { data } = await Vue.prototype.$http.get('/creditor-service-fee/sap-matrix/ist', {
          params: { page, rowsPerPage, filters, sorting },
          paramsSerializer: (params) => {
            return qs.stringify(params, { arrayFormat: 'brackets' });
          }
        });

        return { data: mapServiceFeeMatrixList(data.sapMatrixEntries) };
      } catch (error) {
        return { error };
      }
    },

    async updateCreditor(_, payload) {
      try {
        await Vue.prototype.$http.post(`/creditor/${payload.id}/update`, payload);
        return {};
      } catch (error) {
        showSnackbar({ text: 'serverError', color: 'error' });
        return { error };
      }
    },

    async registerCreditorToSap(_, creditorId) {
      try {
        await Vue.prototype.$http.get(`/creditor/send-to-sap/${creditorId}`);
        return {};
      } catch (error) {
        showSnackbar({ text: 'serverError', color: 'error' });
        return { error };
      }
    },

    async sendRegistrationCaseFile(_, { creditorSapNumber, payload }) {
      try {
        await Vue.prototype.$http.post(
          `/creditor/creditor-cor-send-case-file/${creditorSapNumber}`,
          payload
        );
        return {};
      } catch (error) {
        return { error };
      }
    },

    async chargeSetUpFee(_, creditorId) {
      try {
        const response = await Vue.prototype.$http.put(`/bnet/send-setup-fee/${creditorId}`);
        return { response: response.data };
      } catch (error) {
        showSnackbar({ text: 'serverError', color: 'error' });
        return { error };
      }
    },

    async sendRegistrationMail(_, creditorId) {
      try {
        await Vue.prototype.$http.post(`/user-management/command/send-creditor-registration-mail`, {
          userIdentifier: creditorId
        });
        return {};
      } catch (error) {
        showSnackbar({ text: 'serverError', color: 'error' });
        return { error };
      }
    },

    async createUser(_, payload) {
      try {
        await Vue.prototype.$http.post('/user-management/command/register-creditor', payload);
        return {};
      } catch (error) {
        return { error };
      }
    },

    async updateUser(_, payload) {
      try {
        await Vue.prototype.$http.post('/user-management/command/user/update', payload);
        return {};
      } catch (error) {
        return { error };
      }
    },

    async disableUser(_, creditorId) {
      try {
        const response = await Vue.prototype.$http.post(`/user-management/command/user/disable`, {
          userIdentifier: creditorId
        });
        return { response: response.data };
      } catch (error) {
        showSnackbar({ text: 'serverError', color: 'error' });
        return { error };
      }
    },

    async enableUser(_, creditorId) {
      try {
        const response = await Vue.prototype.$http.post(`/user-management/command/user/enable`, {
          userIdentifier: creditorId
        });
        return { response: response.data };
      } catch (error) {
        showSnackbar({ text: 'serverError', color: 'error' });
        return { error };
      }
    },

    async terminateCreditor(_, creditorId) {
      try {
        const response = await Vue.prototype.$http.post(`/creditor/${creditorId}/terminate`);
        return { response: response.data };
      } catch (error) {
        showSnackbar({ text: 'serverError', color: 'error' });
        return { error };
      }
    },

    async reactivateCreditor(_, creditorId) {
      try {
        const response = await Vue.prototype.$http.post(`/creditor/${creditorId}/reactivate`);
        return { response: response.data };
      } catch (error) {
        showSnackbar({ text: 'serverError', color: 'error' });
        return { error };
      }
    },

    async resetUserPassword(_, creditorId) {
      try {
        await Vue.prototype.$http.post('/user-management/command/admin-reset-user-password', {
          userIdentifier: creditorId
        });
        return {};
      } catch (error) {
        showSnackbar({ text: 'serverError', color: 'error' });
        return { error };
      }
    },

    async fetchCreditorServiceFeeConditions(_, creditorId) {
      try {
        const { data } = await Vue.prototype.$http.get(
          `/creditor-service-fee/creditor-fee-condition/${creditorId}/list`,
          {}
        );
        return { data: mapCreditorServiceFeeConditions(data) };
      } catch (error) {
        showSnackbar({ text: 'serverError', color: 'error' });
        return { error };
      }
    },

    async addCreditorServiceFeeConditions(_, payload) {
      try {
        await Vue.prototype.$http.post(
          '/creditor-service-fee/creditor-fee-condition/create',
          payload
        );
        return {};
      } catch (error) {
        showSnackbar({ text: 'serverError', color: 'error' });
        return { error };
      }
    },

    async deleteCreditorServiceFeeConditions(_, conditionsIdentifier) {
      try {
        await Vue.prototype.$http.delete(
          `/creditor-service-fee/creditor-fee-condition/${conditionsIdentifier}/delete`
        );
        return {};
      } catch (error) {
        showSnackbar({ text: 'serverError', color: 'error' });
        return { error };
      }
    },

    async requestSepaUpdate(_, payload) {
      try {
        await Vue.prototype.$http.post('/creditor-debit-update/command/request', payload);
        return {};
      } catch (error) {
        showSnackbar({ text: 'serverError', color: 'error' });
        return { error };
      }
    },

    async updateCreditorPermissions(_, { identifier, permissions }) {
      try {
        await Vue.prototype.$http.post(`/creditor/${identifier}/permission`, permissions);
        return {};
      } catch (error) {
        showSnackbar({ text: 'serverError', color: 'error' });
        return { error };
      }
    },

    async enableDriverDoc2Portal(_, creditorId) {
      try {
        await Vue.prototype.$http.post(`/user-management/command/user/doc2portal-invoice/enable`, {
          userIdentifier: creditorId
        });
        return {};
      } catch (error) {
        showSnackbar({ text: 'serverError', color: 'error' });
        return { error };
      }
    },

    async disableDriverDoc2Portal(_, creditorId) {
      try {
        await Vue.prototype.$http.post(`/user-management/command/user/doc2portal-invoice/disable`, {
          userIdentifier: creditorId
        });
        return {};
      } catch (error) {
        showSnackbar({ text: 'serverError', color: 'error' });
        return { error };
      }
    },

    async enableDriverLetter2Post(_, creditorId) {
      try {
        await Vue.prototype.$http.post(`/user-management/command/user/doc2portal-document/enable`, {
          userIdentifier: creditorId
        });
        return {};
      } catch (error) {
        showSnackbar({ text: 'serverError', color: 'error' });
        return { error };
      }
    },

    async disableDriverLetter2Post(_, creditorId) {
      try {
        await Vue.prototype.$http.post(
          `/user-management/command/user/doc2portal-document/disable`,
          {
            userIdentifier: creditorId
          }
        );
        return {};
      } catch (error) {
        showSnackbar({ text: 'serverError', color: 'error' });
        return { error };
      }
    },

    async fetchUsers({ commit }, params) {
      commit('SET_LOADING_LIST');

      try {
        const { data } = await Vue.prototype.$http.get('/user-management/user/list', {
          params,
          paramsSerializer: (params) => {
            return qs.stringify(params, { arrayFormat: 'brackets' });
          }
        });

        commit('SET_USERS', { users: mapUsers(data.users), count: data.count });
        commit('RESET_LOADING_LIST');
        return {};
      } catch (error) {
        showSnackbar({ text: 'serverError', color: 'error' });
        commit('RESET_LOADING_LIST');
        return { error };
      }
    },

    async fetchAdmins({ commit }) {
      commit('SET_LOADING_LIST');
      try {
        const response = await Vue.prototype.$http.get('/user-management/user/admin-list');

        commit('SET_ADMINS', mapAdminUsers(response.data));
        commit('RESET_LOADING_LIST');
        return {};
      } catch (error) {
        showSnackbar({ text: 'serverError', color: 'error' });
        commit('RESET_LOADING_LIST');
        return { error };
      }
    },

    async createAdminUser(_, payload) {
      try {
        await Vue.prototype.$http.post('/user-management/command/register-administrator', payload);
        return {};
      } catch (error) {
        return { error };
      }
    },

    async createProductAdminUser(_, payload) {
      try {
        await Vue.prototype.$http.post(
          '/user-management/command/register-product-administrator',
          payload
        );
        return {};
      } catch (error) {
        return { error };
      }
    },

    async addAdminPermission({ commit }, { userIdentifier, permission }) {
      try {
        await Vue.prototype.$http.post('/user-management/command/administrator/add-permission', {
          userIdentifier,
          permission
        });

        commit('ADD_PERMISSION', { userIdentifier, permission });
        return {};
      } catch (error) {
        showSnackbar({ text: 'serverError', color: 'error' });
        return { error };
      }
    },

    async removeAdminPermission({ commit }, { userIdentifier, permission }) {
      try {
        await Vue.prototype.$http.post('/user-management/command/administrator/remove-permission', {
          userIdentifier,
          permission
        });

        commit('REMOVE_PERMISSION', { userIdentifier, permission });
        return {};
      } catch (error) {
        showSnackbar({ text: 'serverError', color: 'error' });
        return { error };
      }
    },

    async fetchRequests({ commit }, params) {
      commit('SET_LOADING_LIST');
      try {
        const { data } = await Vue.prototype.$http.get('/creditor-debit-update/list', {
          params,
          paramsSerializer: (params) => {
            return qs.stringify(params, { arrayFormat: 'brackets' });
          }
        });
        commit('SET_REQUESTS', {
          requests: mapRequests(data.items),
          count: data.count
        });
        commit('RESET_LOADING_LIST');
        return {};
      } catch (error) {
        showSnackbar({ text: 'serverError', color: 'error' });
        commit('RESET_LOADING_LIST');
        return { error };
      }
    },

    async acceptSepaRequest(_, requestId) {
      try {
        await Vue.prototype.$http.post('/creditor-debit-update/command/accept', {
          creditorDebitUpdateIdentifier: requestId
        });
        return {};
      } catch (error) {
        showSnackbar({ text: 'serverError', color: 'error' });
        return { error };
      }
    },

    async declineSepaRequest(_, requestId) {
      try {
        await Vue.prototype.$http.post('/creditor-debit-update/command/decline', {
          creditorDebitUpdateIdentifier: requestId
        });
        return {};
      } catch (error) {
        showSnackbar({ text: 'serverError', color: 'error' });
        return { error };
      }
    },

    async fetchRequestsCSV(_, { page, rowsPerPage, filters, sorting }) {
      try {
        const { data } = await Vue.prototype.$http.get(`/creditor-debit-update/export`, {
          responseType: 'blob',
          params: { page, rowsPerPage, filters, sorting },
          paramsSerializer: (params) => {
            return qs.stringify(params, { arrayFormat: 'brackets' });
          }
        });

        return { file: data };
      } catch (error) {
        showSnackbar({ text: 'serverError', color: 'error' });
        return { error };
      }
    },

    async importStatesOfAffairsCsv(_, formData) {
      try {
        const { data } = await Vue.prototype.$http.post('/state-of-affairs/import', formData);
        return { data };
      } catch (error) {
        return { error };
      }
    },

    async createExternalVoucher(_, formData) {
      try {
        await Vue.prototype.$http.post('/research/external-voucher/create', formData);
        return {};
      } catch (error) {
        showSnackbar({ text: 'serverError', color: 'error' });
        return {
          error
        };
      }
    },

    async fetchDebtorAssignmentList({ commit }, { page, rowsPerPage, filters, sorting }) {
      commit('SET_LOADING_LIST');
      try {
        const { data } = await Vue.prototype.$http.get('/debtor-assignment-list', {
          params: { page, rowsPerPage, filters, sorting },
          paramsSerializer: (params) => {
            return qs.stringify(params, { arrayFormat: 'brackets' });
          }
        });

        commit('SET_DEBTOR_ASSIGNMENT_LIST', {
          items: mapDebtorAssignmentList(data.items),
          count: data.count
        });
        commit('RESET_LOADING_LIST');
        return {};
      } catch (error) {
        showSnackbar({ text: 'serverError', color: 'error' });
        commit('RESET_LOADING_LIST');
        return { error };
      }
    },

    async fetchDebtorAssignmentDetails(_, entryId) {
      try {
        const { data } = await Vue.prototype.$http.get(`/debtor-assignment-details/${entryId}`);
        return { data: mapDebtorAssignmentEntry(data) };
      } catch (error) {
        return { error };
      }
    },

    async submitDebtorAssignment(_, { entryId, debtorId, locationId }) {
      try {
        await Vue.prototype.$http.post(`/debtor-assignment/assign/${entryId}`, {
          debtorIdentifier: debtorId,
          locationIdentifier: locationId
        });
        return {};
      } catch (error) {
        showSnackbar({ text: 'serverError', color: 'error' });
        return { error };
      }
    }
  }
});
