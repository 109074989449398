import AdminPermission from '@/modules/Admin/statics/adminPermission';
import CreditorStatus from '@/modules/Admin/statics/creditorStatus';
import DebtorAssignmentStatus from '@/modules/Admin/statics/debtorAssignmentStatus';
import ExternalVoucherSource from '@/modules/Admin/statics/externalVoucherSource';
import ManualDecisionReason from '@/modules/Admin/statics/manualDecisionReason';
import Role, { UserManagementRole } from '@/statics/role';
import SepaRequestStatus from '@/modules/Admin/statics/requestStatus';
import UserStatus from '@/modules/Admin/statics/userStatus';

export default {
  users: {
    title: 'Benutzer',
    subtitle: 'Wählen Sie einen Nutzer aus, um sich mit diesem anzumelden',
    counted: '1 Benutzer | {count} Benutzer',
    statusOptions: {
      [UserStatus.ACTIVE]: 'Aktiv',
      [UserStatus.DEACTIVATED]: 'Deaktiviert',
      [UserStatus.NEW]: 'Neu',
      [UserStatus.PASSWORD_RESET]: `@:(users.statusOptions.${[UserStatus.ACTIVE]})`,
      [UserStatus.PASSWORD_RESET_REQUESTED]: `@:(users.statusOptions.${[UserStatus.ACTIVE]})`,
      [UserStatus.TERMINATED]: 'Gekündigt'
    },
    userName: 'Benutzername',
    creditorName: 'Name des KMU',
    role: 'Rolle',
    actions: 'Aktionen',
    noData: 'Keine Nutzer vorhanden',
    roles: {
      [Role.CREDITOR]: 'KMU',
      [Role.ADMIN]: 'Admin',
      [Role.PRODUCT_ADMIN]: 'Produkt-Admin',
      [Role.CREDITOR_OBSERVER]: 'Lese- und Downloadberechtigung',
      [Role.CREDITOR_ASSISTANT]: 'Bearbeitungsberechtigung',
      [Role.CREDITOR_MANAGEMENT]: 'Vollberechtigung',
      [UserManagementRole.CREDITOR_USER]: `@:users.roles.${Role.CREDITOR}`,
      [UserManagementRole.COMPANY_USER_OBSERVER]: `@:users.roles.${Role.CREDITOR_OBSERVER}`,
      [UserManagementRole.COMPANY_USER_ASSISTANT]: `@:users.roles.${Role.CREDITOR_ASSISTANT}`,
      [UserManagementRole.COMPANY_USER_MANAGEMENT]: `@:users.roles.${Role.CREDITOR_MANAGEMENT}`
    },
    sentToSap: 'an SAP gesendet',
    sent: 'gesendet',
    notSent: 'nicht gesendet',
    referenceNumber: 'Beleg-Referenz (OBN)',
    sendRegistrationMail: 'Registrierungs E-Mail versenden',
    resetPassword: 'Passwort zurücksetzen',
    passwordResetSuccess: 'Passwort zurückgesetzt',
    requestPasswordReset: 'Soll das Passwort von {name} zurückgesetzt werden?',
    triggerDisableUser: 'Benutzer sperren',
    triggerEnableUser: 'Benutzer entsperren',
    shouldUserDisable: 'Soll {name} wirklich gesperrt werden?',
    shouldUserEnable: 'Soll {name} wieder entsperrt werden?',
    enabled: 'Benutzer entsperrt',
    disabled: 'Benutzer gesperrt',
    switchPortal:
      'Dieser User gehört zu einem anderen Produkt. Wechseln Sie zum Portal von {productName}, um den User auszuwählen.',
    createUser: 'Benutzer anlegen',
    createdUser: 'Benutzer angelegt',
    updateUser: 'Benutzer bearbeiten',
    updatedUser: 'Benutzer bearbeitet',
    deleteUser: 'Benutzer löschen',
    deletedUser: 'Benutzer gelöscht',
    userEmailAlreadyAssigned: 'Es existiert bereits ein Benutzer mit der angegebenen E-Mail-Adresse'
  },

  creditors: {
    title: 'KMU-Verwaltung',
    subtitle: 'Hier können Sie die Anmeldung und Einrichtung von KMU-Konten vornehmen',
    creditorsCounted: '1 KMU | {count} KMUs',
    noData: 'Keine KMUs vorhanden',
    details: 'Details',
    createCreditor: 'KMU anlegen',
    createNextCreditor: 'Weiteren KMU anlegen',
    createdCreditor: 'KMU angelegt',
    contract: 'Vertrag',
    conditions: 'Konditionen',
    serviceFees: 'Servicegebühren',
    sapKey: 'SAP-Key',
    legalForm: 'Rechtsform',
    serviceFee: 'Servicegebühr',
    setUpFee: 'Einrichtungsgebühr',
    buergelScore: 'Bonitäts-Index',
    ddmIdentifier: 'CMS-Key',
    wzCode: 'WZ-Code',
    cspIdentifier: 'ONR-Nummer',
    contractDate: 'Vertragsbeginn',
    forCurrentServiceFeePeriod: 'im aktuellen Vertragsjahr',
    forNextServiceFeePeriod: 'im nächsten Vertragsjahr',
    editCreditor: 'KMU bearbeiten',
    triggerSetUpFee: 'Einrichtungsgebühr auslösen',
    triggerSapRegistration: 'Für SAP registrieren',
    triggerSapRegistrationAgain: 'Erneut an SAP senden',
    requestSapRegistration: 'Möchten Sie das KMU "{name}" für SAP registrieren?',
    creditorSentToSap: 'KMU an SAP versandt',
    requestSetUpFee:
      'Möchten Sie die Einrichtungsgebühr in Höhe von {setUpFee} für das KMU "{name}" auslösen?',
    setUpFeeAlreadyCharged: 'Für das KMU wurde bereits zuvor eine Einrichtungsgebühr ausgelöst.',
    confirmRenewedFee: 'Die Einrichtungsgebühr soll erneut ausgelöst werden',
    setUpFeeCharged: 'Einrichtungsgebühr ausgelöst',
    requestSepaUpdate: {
      title: 'Bankverbindung aktualisieren',
      info: 'Bitte geben Sie die  Daten zum neuen SEPA-Lastschriftmandat des KMU an. Ihr Änderungsantrag wird anschließend an einen Mitarbeiter zur Freigabe weitergeleitet.',
      submitted:
        'Wir haben Ihren Änderungsantrag erfasst und zur Freigabe weitergeleitet. Über das Ergebnis der Prüfung werden Sie per E-Mail informiert.'
    },
    sendRegistrationMail: 'Begrüßungsmail versenden',
    requestRegistrationMail:
      'Diese Funktion versendet eine Begrüßungsmail mit einem einheitlichen Start-Passwort an {name}.',
    registrationMailWarning:
      'Achtung: Der Account muss mit dem aktuellen Standard-Passwort erstellt worden sein.',
    sentRegistrationMail: 'Begrüßungsmail versandt',
    setCreditorPermissions: {
      title: 'Rechte bearbeiten',
      requestChange:
        'Welche Rechte zur Nutzung der Portal-Funktionen soll das KMU {name} erhalten?',
      directPayment: '@:vouchers.reportDirectPayment',
      directPaymentInfo:
        'In den Belegdetails können Direktzahlungen zu einer Rechnung eingereicht werden.',
      determineSolvency: '@:debtors.checkSolvency',
      determineSolvencyInfo: 'In den Kundendetails kann die Bonität des Kunden ermittelt werden.',
      vrsManualCheckMandatory: 'KMU immer prüfen',
      vrsManualCheckMandatoryInfo: 'Belege des KMU sollen immer manuell geprüft werden.',
      confirmChange: 'Berechtigungen geändert'
    },
    setDriverPermissions: {
      title: 'Drucker-Freigabe verwalten',
      requestChange: 'Welche Rechte zur Nutzung des Drucker-Treibers soll das KMU {name} erhalten?',
      uploadDoc2Portal: 'Rechnungen per ePost-Mailer einreichen',
      sendLetter2Post: 'Allgemeine Dokumente (nicht Rechnungen) per ePost-Mailer versenden',
      confirmChange: 'Drucker-Freigabe geändert'
    },
    manageConditions: {
      title: 'Konditionen verwalten',
      confirmChange: 'Konditionen aktualisiert',
      validSince: 'Gültig seit',
      validOn: 'Gültig ab',
      valid: 'Gültig',
      createdOn: 'Angelegt am',
      deleteTitle: 'Konditionen löschen',
      deleteInfo: 'Bitte bestätigen Sie, dass die folgende Konditionen gelöscht werden sollen:',
      noData: 'Keine Einträge',
      conditions: 'Konditionen',
      addNew: 'Neu anlegen',
      addNewConditions: 'Neue Konditionen anlegen',
      errorStartDateAlreadyTaken:
        'Es wurden bereits Konditionen zu diesem Datum angelegt. Bitte entfernen Sie diese oder wählen ein anderes Datum.'
    },
    terminate: {
      triggerTerminateCreditor: 'KMU kündigen',
      shouldCreditorBeTerminated: 'Möchten Sie dem KMU {name} wirklich kündigen?',
      hintTerminate:
        'Alle Benutzer des KMUs können sich weiterhin im Portal anmelden, um Daten einzusehen und Aktionen vorzunehmen, jedoch keine neuen Belege oder Kunden anlegen.',
      creditorTerminated: 'Kündigung gesetzt',
      triggerReactivateCreditor: 'Kündigung zurücknehmen',
      shouldCreditorBeReactivated:
        'Soll die Kündigung gegenüber dem KMU {name} wieder zurückgenommen werden?',
      hintReactivate:
        'Alle Benutzer des KMUs erhalten wieder vollen Zugang zum Portal. Die Drucker-Freigabe muss ggf. erneut erteilt werden.',
      creditorReactivated: 'Kündigung zurückgenommen'
    },
    registrationState: 'Verarbeitungsstatus',
    registrationStates: {
      sapRegistered: 'An SAP gesendet',
      setUpFeeCharged: 'Einrichtungsgebühr',
      userCreated: 'Nutzer angelegt'
    },
    creditorEmailAlreadyAssigned: 'Es existiert bereits ein KMU mit der angegebenen E-Mail-Adresse',
    ddmOrCspAlreadyAssigned: 'CMS-Key und/oder ONR-Nummer sind bereits vergeben',
    deviatingUserEmail: 'abweichende User-E-Mail',
    lastUpdated: 'zuletzt aktualisiert',
    creditorName: 'Name des KMU',
    statusOptions: {
      [CreditorStatus.ACTIVE]: 'Aktiv',
      [CreditorStatus.TERMINATED]: 'Gekündigt'
    },
    sendRegistrationCaseFile: {
      title: 'Registrierungsdaten übermitteln',
      submitted: 'Registrierungsdaten wurden übermittelt',
      apiIdentifier: 'Web-API-Identifier',
      statusCode: 'Status-Code',
      statusText: 'Status-Text'
    }
  },

  admins: {
    title: 'Administratoren',
    subtitle: 'Verwaltung der Benutzer mit Administratorrechten',
    permissions: 'Berechtigungen',
    permissionsCounted: 'keine Berechtigungen | 1 Berechtigung | {count} Berechtigungen',
    settings: 'Settings',
    createAdminUser: 'Admin-Benutzer anlegen',
    createProductAdminUserError:
      'Bei der Anlage sind Fehler aufgetreten. Für folgende Produkte konnte kein Benutzer angelegt werden:',
    createAdminUserError:
      'Es ist ein Fehler aufgetreten. Der Benutzer konnte nicht angelegt werden.'
  },

  requests: {
    title: 'Anfragen',
    subtitle: 'Hier finden Sie eine Übersicht über die offenen und bearbeiteten Anfragen',
    statusOptions: {
      [SepaRequestStatus.OPEN]: 'Offen',
      [SepaRequestStatus.ACCEPTED]: 'Akzeptiert',
      [SepaRequestStatus.DECLINED]: 'Abgelehnt'
    },
    requestsCounted: '{count} Anfrage | {count} Anfragen',
    noData: 'Keine Anfragen vorhanden',
    makeDecision: 'Bitte treffen Sie eine Entscheidung',
    decidedOn: 'Bearbeitet am',
    sepaData: 'SEPA-Daten',
    sepaUpdate: 'Änderung der Bankverbindung',
    acceptRequest: 'Anfrage wird akzeptiert',
    declineRequest: 'Anfrage wird abgelehnt',
    newBankDetails: 'Neue Bankverbindung',
    confirmedTitleAccepted: 'Änderung akzeptiert',
    confirmedTitleDeclined: 'Änderung abgelehnt',
    confirmedInfoAccepted:
      'Wir haben Ihre Entscheidung erhalten. Die Bankverbindung des KMU wird in Kürze aktualisiert und der Antragsteller per E-Mail über Ihre Entscheidung informiert.',
    confirmedInfoDeclined:
      'Wir haben Ihre Entscheidung erhalten. Der Antragsteller wird per E-Mail über Ihre Entscheidung informiert.',
    csvExportFilename: 'Anfragen-Export-{date}.csv',
    csvExportInfo: 'Liste als CSV exportieren',
    createdFromCurrentUser: 'Anfrage selbst erstellt'
  },

  adminActions: {
    title: 'Funktionen und Einstellungen',
    subtitle:
      'Hier können Sie die globalen Einstellungen des Portals verwalten und nützliche Support-Funktionen ausführen',
    noActionAvailable: 'Keine Aktion verfügbar oder Berechtigung nicht erteilt.',
    externalVoucher: {
      info: 'Testen Sie den Belegupload aus einem externen System in das Portal.',
      create: 'Beleg aus externer Quelle anlegen',
      created: 'Die Belegdaten wurden übermittelt',
      source: 'Quelle',
      sourceOptions: {
        [ExternalVoucherSource.DOKUGUIDE_EPOST]: 'E-POST docuguide',
        [ExternalVoucherSource.DEGENER]: 'DEGENER',
        [ExternalVoucherSource.PORTAL_UPLOAD]: 'Portal-Upload',
        [ExternalVoucherSource.RENTAS]: 'Rentas',
        [ExternalVoucherSource.MINDFACTORY]: 'Mindfactory',
        [ExternalVoucherSource.SAGE_SCHWINDT]: 'Sage (SCHWINDT)',
        [ExternalVoucherSource.ANWALT_FACTORING]: 'Anwaltsfactoring',
        [ExternalVoucherSource.BILLOMAT]: 'Billomat',
        [ExternalVoucherSource.OPENHANDWERK]: 'openHandwerk',
        [ExternalVoucherSource.POINT_S]: 'point S',
        [ExternalVoucherSource.DATAC]: 'DATAC',
        [ExternalVoucherSource.MEIN_ONLINE_BUERO]: 'meinOnlineBüro',
        [ExternalVoucherSource.VHV]: 'VHV',
        [ExternalVoucherSource.FACTORIX]: 'Factorix'
      },
      voucherData: 'Belegdaten',
      debtorData: 'Kundendaten',
      discountRate: 'Skonto',
      termsOfPayment: 'Zahlungsbedingungen',
      externalIdentifier: 'UID',
      hintVoucherFormat:
        'Format beachten: No<Transporeon-Nr.>#<Transporeon-ID> (z. B.: No4711-2022#700000001)',
      languageKey: 'Sprachschlüssel',
      errors: {
        errorBnet: 'Die Übertragung an bnet ist fehlgeschlagen.',
        errorInvalidCreditor: 'Das KMU hat keinen gültigen Schnittstellen-Schlüssel.',
        errorMissingVoucherFile: 'Der Beleg fehlt oder ist fehlerhaft.'
      }
    },
    importStatesOfAffairs: {
      info: 'Aktualisieren Sie die im Portal verarbeitbaren SAP-Sachstände.',
      title: 'Sachstandsmatrix importieren',
      imported: 'Sachstandsmatrix importiert',
      importedCleanUpFailed: 'Sachstandsmatrix importiert, Bereinigung nicht möglich',
      selectCsv: 'CSV-Datei auswählen',
      sourceInfo:
        'Folgen Sie dem <a href="{link}" target="_blank">Link</a>, um die aktuelle Sachstandstabelle herunterzuladen.',
      convertTable:
        'Die Tabelle muss vor dem Upload manuell in das Format <b>CSV UTF-8</b> überführt werden!',
      importSucceeded: 'Der Import war erfolgreich und die Sachstände wurden aktualisiert.',
      importSucceededCleanUpFailed:
        'Der Import war erfolgreich und die Sachstände wurden aktualisiert. Bisherige Sachstände konnten nicht bereinigt werden. Für alle Sachstände mit einem älteren "last_import_date" sind ggf. Arbeitsvorräte vorhanden.',
      importFailed:
        'Der Import war nicht erfolgreich und die Sachstände wurden nicht oder nicht vollständig aktualisiert. Bitte prüfen Sie die übergebene Datei und versuchen Sie es erneut.'
    },
    assignDebtor: {
      title: '@:assignDebtors.title',
      info: 'Sehen Sie die Fälle ein, in denen der Debitor nicht eindeutig identifiziert werden konnte und weisen Sie diesen manuell zu.'
    }
  },

  assignDebtors: {
    title: 'Debitoren zuweisen',
    debtor: 'Debitor',
    caseStatus: 'Fallstatus',
    subtitle:
      'In den nachstehenden Fällen konnte der Debitor nicht eindeutig identifiziert werden und muss manuell zugewiesen werden.',
    status: {
      [DebtorAssignmentStatus.OPEN]: 'Offen',
      [DebtorAssignmentStatus.PROGRESS]: 'In Bearbeitung',
      [DebtorAssignmentStatus.DONE]: 'Erledigt'
    },
    actionDialogInfoInvoice:
      'Bitte wählen Sie die Versandadresse für die nachstehende Rechnung aus. Alternativ können Sie die ausgelesenen Rechnungsdaten als neue Versandadresse für einen bekannten oder neu anzulegenden Debitoren übernehmen.',
    actionDialogInfoDebtorRequest:
      'Bitte wählen Sie die Versandadresse für die nachstehende Anfrage aus. Alternativ können Sie die Adressdaten als neue Versandadresse für einen bekannten oder neu anzulegenden Debitoren übernehmen.',
    debtorRequest: 'Anfrage zum Debitor',
    invoiceAddress: 'Rechnungsadresse',
    documentId: 'Dokument-ID',
    bnetId: 'bnet-ID',
    uploadDate: 'Upload-Datum',
    noData: 'Keine Einträge vorhanden',
    creationDate: 'Anlagedatum',
    foundDebtors: 'Gefundene Debitoren',
    cspIdentifier: 'CSP-Identifier',
    normalizedAddress: 'normierte Adresse',
    limitAddress: 'Limit-Adresse',
    limitUpdated: 'Limit geändert am',
    limitValidUntil: 'Limit gültig bis',
    invoicesCount: 'Anzahl Rechnungen',
    openInvoices: 'Offene Rechnungen',
    firstInvoiceUploadDate: 'Erste Rechnung',
    lastInvoiceUploadDate: 'Letzte Rechnung',
    activeLocationsCounted: '1 aktive Versandadresse | {count} aktive Versandadressen',
    showAdditionalData: 'Weitere Informationen einblenden',
    hideAdditionalData: 'Weitere Informationen ausblenden',
    debtorSelected: 'Debitor ausgewählt',
    createNewLocationInvoice:
      'Neue Versandadresse mit den normierten Adressdaten der Rechnung anlegen',
    createNewLocationDebtorRequest:
      'Neue Versandadresse mit den normierten Adressdaten der Anfrage anlegen',
    createNewDebtorInvoice: 'Neuen Debitor mit der Limit-Adresse der Rechnung anlegen',
    createNewDebtorDebtorRequest: 'Neuen Debitor mit der Limit-Adresse der Anfrage anlegen',
    confirmSubmit: 'Zuweisung bestätigen',
    successTitle: 'Zuweisung erfolgreich',
    successInfo: 'Der Fall wurde erfolgreich bearbeitet.',
    confirmAssignmentInvoice:
      'Die Rechnung wird mit Ihrer Bestätigung den folgenden Debitoren- und Adressdaten zugewiesen:',
    confirmAssignmentDebtorRequest:
      'Die Anfrage wird mit Ihrer Bestätigung den folgenden Debitoren- und Adressdaten zugewiesen:',
    newDebtor: 'Es wird ein neuer Debitor mit der Limit-Adresse angelegt',
    newLocation: 'Es wird eine neue Versandadresse mit den normierten Adressdaten angelegt',
    manualDecisionReason: 'Grund',
    manualDecisionReasons: {
      [ManualDecisionReason.IDENTIFY_WITH_LIMIT_ADDRESS_AND_NAME]:
        'Mehrere Debitoren mit gleichem Namen und Limit-Adresse',
      [ManualDecisionReason.IDENTIFY_WITH_LOCATION_ADDRESS_AND_NAME]:
        'Mehrere Debitoren mit gleicher Versandadresse',
      [ManualDecisionReason.IDENTIFY_WITH_LIMIT_ADDRESS_AND_CUSTOM_DEBTOR_NUMBER]:
        'Einen oder mehrere Debitoren mit gleicher Limit-Adresse und Kundennr.',
      [ManualDecisionReason.IDENTIFY_WITH_LOCATION_ADDRESS_AND_CUSTOM_DEBTOR_NUMBER]:
        'Einen oder mehrere Debitoren mit gleicher Versandadresse und Kundennr.',
      [ManualDecisionReason.IDENTIFY_WITH_CUSTOMER_LIST]: 'Mehrere Treffer in der Kundenliste'
    }
  },

  permissions: {
    [AdminPermission.CREDITOR_REGISTRATION]: '@:creditors.createCreditor',
    [AdminPermission.UPDATE_USER]: '@:users.updateUser',
    [AdminPermission.CREDITOR_SAP_REGISTRATION]: '@:creditors.triggerSapRegistration',
    [AdminPermission.CREDITOR_SETUP_FEE]: '@:creditors.triggerSetUpFee',
    [AdminPermission.REGISTRATION_MAIL]: '@:creditors.sendRegistrationMail',
    [AdminPermission.DISABLE_USER]: '@:users.triggerDisableUser',
    [AdminPermission.RESET_USER_PASSWORD]: '@:users.resetPassword',
    [AdminPermission.ENABLE_DOC2_PORTAL]: '@:creditors.setDriverPermissions.title',
    [AdminPermission.APPROVE_SEPA_UPDATE]: 'Freigabe der Aktualisierung von Bankverbindungen',
    [AdminPermission.ASSIGN_DEBTOR]: '@:assignDebtors.title'
  }
};
