import Role from '@/statics/role';
import AdminPermission from '@/modules/Admin/statics/adminPermission';
import {
  ADMIN_REFRESH_TOKEN_KEY,
  ADMIN_TOKEN_KEY,
  AUTH_TOKEN_KEY,
  REFRESH_TOKEN_KEY
} from '@/plugins/vue-auth';
import { NAMESPACE } from '@/modules/Authentication/store';
import { mapActions, mapMutations, mapState } from 'vuex';

export default {
  data() {
    return {
      admin: this.$auth.token(ADMIN_TOKEN_KEY)
    };
  },

  computed: {
    ...mapState(NAMESPACE, ['adminView']),
    isAdmin() {
      return !!this.admin || this.$auth.check(Role.ADMIN);
    },
    isAdminAsCreditor() {
      return this.isAdmin && !this.$auth.check(Role.ADMIN);
    },
    isProductAdmin() {
      return this.$auth.check(Role.PRODUCT_ADMIN);
    },
    isSuperAdmin() {
      return this.$auth.check(Role.SUPER_ADMIN);
    },
    hasAdminView() {
      return this.adminView && this.isAdmin;
    },
    hasApproveSepaUpdatePermission() {
      return this.hasPermission(AdminPermission.APPROVE_SEPA_UPDATE);
    },
    hasCreditorCreationPermission() {
      return this.hasPermission(AdminPermission.CREDITOR_REGISTRATION);
    },
    hasSendRegisterEmailPermission() {
      return this.hasPermission(AdminPermission.REGISTRATION_MAIL);
    },
    hasUpdatePermission() {
      return this.hasPermission(AdminPermission.UPDATE_USER);
    },
    hasDisableUserPermission() {
      return this.hasPermission(AdminPermission.DISABLE_USER);
    },
    hasPasswordResetPermission() {
      return this.hasPermission(AdminPermission.RESET_USER_PASSWORD);
    },
    hasSapRegistrationPermission() {
      return this.hasPermission(AdminPermission.CREDITOR_SAP_REGISTRATION);
    },
    hasSetupFeePermission() {
      return this.hasPermission(AdminPermission.CREDITOR_SETUP_FEE);
    },
    hasEnableDoc2PortalPermission() {
      return this.hasPermission(AdminPermission.ENABLE_DOC2_PORTAL);
    },
    hasAssignDebtorPermission() {
      return this.hasPermission(AdminPermission.ASSIGN_DEBTOR);
    },
    role() {
      return Role;
    },
    showCreditorUserList() {
      return (
        this.$auth.check(Role.ADMIN) &&
        (this.hasCreditorCreationPermission ||
          this.hasSapRegistrationPermission ||
          this.hasSetupFeePermission ||
          this.hasUpdatePermission ||
          this.hasDisableUserPermission ||
          this.hasSendRegisterEmailPermission ||
          this.hasPasswordResetPermission)
      );
    }
  },

  methods: {
    ...mapActions(['reset']),

    ...mapMutations(NAMESPACE, {
      updateAdminView: 'SET_ADMIN_VIEW',
      setLoadingCurrent: 'SET_LOADING_CURRENT',
      resetLoadingCurrent: 'RESET_LOADING_CURRENT',
      setCurrent: 'SET_CURRENT'
    }),

    setAdminView(enabled) {
      this.updateAdminView(enabled);
      window.localStorage.setItem('dvkmu_admin_view', enabled);
    },

    hasPermission(permission) {
      return (
        this.isSuperAdmin || (this.$auth.user().permissions.admin?.includes(permission) ?? false)
      );
    },

    logout() {
      this.setLoadingCurrent();

      this.$auth
        .logout({
          redirect: '/login'
        })
        .then(() => {
          this.reset();
          this.$auth.token(AUTH_TOKEN_KEY, null);
          this.$auth.token(REFRESH_TOKEN_KEY, null);
          this.$auth.token(ADMIN_TOKEN_KEY, null);
          this.$auth.token(ADMIN_REFRESH_TOKEN_KEY, null);
          this.resetLoadingCurrent();
        });
    }
  }
};
