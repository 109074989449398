export const AdminPermission = {
  CREDITOR_REGISTRATION: 'creditor_registration',
  CREDITOR_SAP_REGISTRATION: 'creditor_sap_registration',
  CREDITOR_SETUP_FEE: 'creditor_setup_fee',
  REGISTRATION_MAIL: 'registration_mail',
  DISABLE_USER: 'disable_user',
  UPDATE_USER: 'update_user',
  RESET_USER_PASSWORD: 'reset_user_password',
  APPROVE_SEPA_UPDATE: 'approve_sepa_update',
  ENABLE_DOC2_PORTAL: 'enable_doc2portal',
  ASSIGN_DEBTOR: 'assign_debtor'
};

export const ADMIN_PERMISSIONS = Object.values(AdminPermission);

export default AdminPermission;
