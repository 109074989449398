import Vue from 'vue';
import Vuex from 'vuex';
import showSnackbar from '@/helper/showSnackbar';

import authentication, {
  NAMESPACE as AUTHENTICATION_NAMESPACE
} from '../modules/Authentication/store';
import dashboard, { NAMESPACE as DASHBOARD_NAMESPACE } from '../modules/Dashboard/store';
import vouchers, { NAMESPACE as VOUCHER_NAMESPACE } from '../modules/Vouchers/store';
import unprocessed, { NAMESPACE as UNPROCESSED_NAMESPACE } from '../modules/Unprocessed/store';
import debtors, { NAMESPACE as DEBTOR_NAMESPACE } from '../modules/Debtors/store';
import permanentDebts, {
  NAMESPACE as PERMANENT_DEBTS_NAMESPACE
} from '@/modules/PermanentDebts/store';
import user, { NAMESPACE as USER_NAMESPACE } from '../modules/User/store';
import admin, { NAMESPACE as ADMIN_NAMESPACE } from '../modules/Admin/store';
import failure from '../modules/Failure/store';
import onboarding, { NAMESPACE as ONBOARDING_NAMESPACE } from '@/onboarding/store';

Vue.use(Vuex);

const initialState = () => ({
  showSidebar: false
});

export default new Vuex.Store({
  modules: {
    authentication: authentication(Vue),
    dashboard: dashboard(Vue),
    vouchers: vouchers(Vue),
    unprocessed: unprocessed(Vue),
    debtors: debtors(Vue),
    permanentDebts: permanentDebts(Vue),
    user: user(Vue),
    admin: admin(Vue),
    failure: failure(Vue),
    onboarding: onboarding(Vue)
  },

  state: initialState(),

  mutations: {
    TOGGLE_SIDEBAR(state) {
      state.showSidebar = !state.showSidebar;
    },
    RESET_STATE(state) {
      const initial = initialState();
      Object.keys(initial).forEach((key) => {
        state[key] = initial[key];
      });
    }
  },

  actions: {
    downloadFile(_, source) {
      return Vue.prototype.$http
        .get(source, { responseType: 'blob' })
        .then((result) => result.data)
        .catch(() => {
          showSnackbar({ text: 'serverError', color: 'error' });
        });
    },
    reset({ commit }) {
      commit('RESET_STATE');
      commit(`${AUTHENTICATION_NAMESPACE}/RESET_STATE`);
      commit(`${USER_NAMESPACE}/RESET_STATE`);
      commit(`${DASHBOARD_NAMESPACE}/RESET_STATE`);
      commit(`${VOUCHER_NAMESPACE}/RESET_STATE`);
      commit(`${DEBTOR_NAMESPACE}/RESET_STATE`);
      commit(`${PERMANENT_DEBTS_NAMESPACE}/RESET_STATE`);
      commit(`${UNPROCESSED_NAMESPACE}/RESET_STATE`);
      commit(`${ADMIN_NAMESPACE}/RESET_STATE`);
      commit(`${ONBOARDING_NAMESPACE}/RESET_STATE`);
    }
  }
});
